

































































































































































































































import Vue from "vue";
import { fulfillmentStatuses, statuses } from "@/constants/orders";
import coreApiClient from "@/services/apis/coreApiClient";
import stateManager from "@common/services/stateManager";
import ActionHistoryVue from "./base-barcode-scan-page/partials/ActionHistory.vue";

export default Vue.extend({
  props: [
    "prepareHandler",
    "actionHandler",
    "confirmHandler",
    "autoActionText",
    "actionIcon",
    "actionText",
    "actionSuccessText",
    "defaultAutoHandlingEnabled",
    "requiredFullfillmentStatuses",
    "succeedCheckHandler",
    "customPrinterEnabled",
  ],
  data() {
    return {
      self: this,
      loading: false,
      setting: null,
      autoHandlingEnabled: false,
      barcodeScanning: false,
      barcode: "",
      printerName: "",
      barcodeResult: null,
      actionLoading: false,
      actionSuccess: false,
      actionError: null,
      fulfillmentStatuses,
      statuses,
      actionHistoryBtnOptions: {
        attrs: {
          icon: true,
        },
        content: {
          icon: "mdi-view-list-outline",
        },
        target: {
          dialog: {
            attrs: {
              width: "80%",
            },
            content: {
              autofocus: false,
              toolbar: {
                title: "Action History",
                subTitle(self) {
                  const btn = self.context();
                  const { barcodeResult } = btn.context();
                  return barcodeResult.barcode;
                },
              },
              content: {
                type: ActionHistoryVue,
                attrs: {},
                makeAttrs(attrs: any = {}, dialog) {
                  const { barcodeResult } = dialog.context().context();
                  attrs.barcode = barcodeResult.barcode;
                  return attrs;
                },
              },
              buttons: {
                cancel: {
                  content: {
                    text: "Close",
                  },
                },
              },
            },
          },
        },
      },
    };
  },
  computed: {
    actionable() {
      if (
        this.barcodeResult?.productItem &&
        this.barcodeResult?.order?.status === "processing"
      ) {
        if (
          !this.requiredFullfillmentStatuses ||
          this.requiredFullfillmentStatuses.includes(
            this.barcodeResult.productItem.fulfillmentStatus
          )
        ) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    async scanBarcode() {
      if (!this.barcode) {
        return;
      }
      this.barcodeScanning = true;
      try {
        const result = await this.prepareHandler(this.barcode, this.setting);
        if (result) {
          this.barcodeResult = result;
          this.actionSuccess = false;
          this.actionError = null;
          if (this.succeedCheckHandler?.(result)) {
            this.actionSuccess = true;
          } else if (this.autoHandlingEnabled) {
            if (this.actionable) {
              await this.action();
            }
          }
        }
      } finally {
        this.barcodeScanning = false;
        this.reset();
      }
    },
    async action() {
      const barcode = this.barcodeResult?.barcode;
      if (!barcode) {
        return;
      }
      this.actionLoading = true;
      try {
        await this.actionHandler(
          barcode,
          this.barcodeResult,
          this.setting,
          this.printerName
        );
        if (this.confirmHandler) {
          await this.confirmHandler(barcode, this.barcodeResult, this.setting);
        }
        this.actionSuccess = true;
        this.actionError = null;
      } catch (e) {
        this.actionSuccess = false;
        this.actionError = e;
      } finally {
        this.actionLoading = false;
      }
    },
    async reset() {
      setTimeout(() => {
        this.barcode = "";
        this.$refs.barcodeInput?.focus();
      });
    },
  },
  watch: {
    autoHandlingEnabled(value, previousValue) {
      if (!!value === !!previousValue) {
        return;
      }
      stateManager.set(`${this.$route.path}_autoHandlingEnabled`, value);
    },
    printerName(value, previousValue) {
      if (!this.customPrinterEnabled) {
        return;
      }
      if (value === previousValue) {
        return;
      }
      stateManager.set(`${this.$route.path}_printerName`, value);
    },
  },
  async created() {
    this.autoHandlingEnabled = stateManager.get(
      `${this.$route.path}_autoHandlingEnabled`,
      !!this.defaultAutoHandlingEnabled
    );
    if (this.customPrinterEnabled) {
      this.printerName = stateManager.get(
        `${this.$route.path}_printerName`,
        ""
      );
    }
    this.loading = true;
    this.setting = await coreApiClient.call("setting", "get");
    this.loading = false;
    this.reset();
  },
  destroyed() {},
});
