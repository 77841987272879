
















import coreApiClient from "@/services/apis/coreApiClient";
import Vue from "vue";
import BaseBarcodeScanPage from "./BaseBarcodeScanPage.vue";
export default Vue.extend({
  components: { BaseBarcodeScanPage },
  data() {
    return {
      prepareHandler: async (barcode) => {
        return await coreApiClient.call("orderFulfillment", "preparePrintMain", {
          barcode,
        });
      },
      succeedCheckHandler: (result) => {
        return result?.productItem?.fulfillmentMainPrintedQuantityIndexes?.includes(result?.quantityIndex);
      },
      actionHandler: async (barcode) => {
        await coreApiClient.callRaw("orderFulfillment", "printMain", {
          barcode,
        });
      },
    };
  },
});
