export const actionTypes = {
    "Create": "Create",
    "Update": "Update",
    "Delete": "Delete",
    "Login": "Login",
    "Export": "Export Excel",
    "Import": "Import Excel",
    "CreateMany": "Create Many",
    "FulfillmentPrintBarcode": "Fulfillment Print Barcode",
    "FulfillmentPrintDesign": "Fulfillment Print Design",
    "FulfillmentPrintMain": "Fulfillment Print",
    "FulfillmentSetQualityCheckFailed": "Fulfillment Reject",
    "FulfillmentQualityCheck": "Fulfillment Quality Check",
    "FulfillmentPackage": "Fulfillment Package",
}

export const targetTypes = {
    "ActionLog": "Action Log",
    "Order": "Order",
    "ProductImport": "Product Import",
    "Product": "Product",
    "ProductVariant": "Product Variant",
    "Setting": "Setting",
    "ShippingVendor": "Shipping Vendor",
    "Store": "Store",
    "User": "User",
}
